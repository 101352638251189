/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import string from 'lodash/string';
import { Box, Container, Checkbox, Image } from '@chakra-ui/react';
import parse from 'html-react-parser';
import SEO from '../components/seo';
import Layout from '../components/layout';
// import CustomButton from '../components/custom-button';
import '../styles/articles.scss';

const Articles = (props) => {
  const { data } = props;
  const { truncate } = string;
  const [postsList, setPostsList] = useState(data.allWpArticle.edges);
  const [tags, setTags] = useState([]);
  const handleFilters = (event, tag) => {
    if (event.target.checked) {
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
      setTags(tags);
    } else {
      const index = tags.indexOf(tag);
      tags.splice(index, 1);
      setTags(tags);
    }

    const filterResult = [];

    data.allWpArticle.edges.filter((node) => {
      if (tags.length === 0) {
        filterResult.push(node);
      } else if (tags.length === 1) {
        const foundPost = node.node.articleCategories.nodes.find(
          (post) => post.slug === tags[0]
        );
        if (foundPost) {
          filterResult.push(node);
        }
      } else {
        const result = [];
        tags.forEach((subtag) => {
          const foundPost = node.node.articleCategories.nodes.find(
            (post) => post.slug === subtag
          );
          if (foundPost) {
            result.push(node);
          }
        });
        const toFindDuplicates = (a) =>
          a.filter((item, index) => a.indexOf(item) !== index);
        const duplicateElements = toFindDuplicates(result);
        if (duplicateElements.length !== 0) {
          duplicateElements.forEach((dup) => {
            if (dup.node.articleCategories.nodes.length >= tags.length) {
              filterResult.push(dup);
            }
          });
        }
      }
    });
    const uniqueResult = [...new Set(filterResult)];
    setPostsList(uniqueResult);
  };

  return (
    <Layout>
      <SEO
        title="Articles About Nutrient Solutions and Crystal Green Fertilizer | Ostara"
        description="Discover stories and data behind Crystal Green fertilizer's higher yields and our nutrient recovery technology in these articles from Ostara, partners and customers. "
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Articles"
      />
      <main>
        <Box w="100%" p={4} className="articles-body">
          <Container
            className="articles-body--container"
            maxW={1400}
            marginTop={100}
          >
            <h1 className="articles-body--heading">Articles</h1>

            <div className="downloads-filter">
              <h3>Filter by:</h3>
              {data.allWpArticleCategory.edges.map((tag) => (
                <Checkbox
                  type="checkbox"
                  key={tag.node.slug}
                  className="custom-checkbox"
                  onChange={(event) => handleFilters(event, tag.node.slug)}
                >
                  <span>✓</span>
                  {tag.node.name}
                </Checkbox>
              ))}
            </div>
          </Container>
          <Container maxW={1400}>
            {postsList && postsList.length > 0
              ? postsList.map((item) => (
                  <Link key={item.node.id} to={`/articles/${item.node.slug}`}>
                    <div className="articles--item" key={item.node.id}>
                      <div className="articles-title">
                        <Image
                          src={item.node.featuredImage.node.sourceUrl}
                          alt="Crop Data Overlay img"
                          className="articles-thumbnail"
                        />
                        <div className="articles-text">
                          <h5>{item.node.title}</h5>
                          {parse(
                            truncate(item.node.excerpt, {
                              length: 120, // maximum 30 characters
                              separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
                            })
                          )}
                          <p className="articles-release-date">SEP 12, 2021</p>
                          <div className="articles-tags">
                            {item.node.articleCategories.nodes.map((tag) => (
                              <p key={tag.id}>{tag.name}</p>
                            ))}
                          </div>
                        </div>
                      </div>
                      <Link
                        className="articles-readmore"
                        to={`/articles/${item.node.slug}`}
                      >
                        Read
                      </Link>
                    </div>
                  </Link>
                ))
              : ''}
          </Container>
          {/* <CustomButton maxW={230} to="/product" marginTop={10}>
            LOAD MORE
          </CustomButton> */}
        </Box>
      </main>
    </Layout>
  );
};

export default Articles;
export const pageQuery = graphql`
  query {
    allWpArticle {
      edges {
        node {
          id
          slug
          title
          excerpt
          featuredImage {
            node {
              sourceUrl
            }
          }
          articleCategories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }
    allWpArticleCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
